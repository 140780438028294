import axios from 'axios';

export const getMACAddress = async (locationID) => {
    const url = `/.netlify/functions/getMACAddress`;

    try {
        const res = await axios.post(url, { locationID });
        return res.data; // Return the details
    } catch (err) {
        console.error('Error fetching internet details:', err);
        return null; // Return null on error
    }
};
