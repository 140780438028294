import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { getMACAddress } from '../../../../functions/getMACAddress';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';

const Network = () => {
    const [macAddresses, setMacAddresses] = useState([]); // State for multiple MAC addresses
    const { userLocation } = useContext(UserLocationContext); // Get location context

    const fetchMacAddresses = async () => {
        try {
            console.log('Fetching MAC addresses for:', userLocation?._id);
            if (!userLocation?._id) {
                console.error('No user location ID found');
                return;
            }

            const response = await getMACAddress(userLocation._id);
            console.log('Fetched MAC addresses:', response);

            if (response?.macAddresses?.length) {
                setMacAddresses(response.macAddresses);
            } else {
                setMacAddresses(['MAC address not found']);
            }
        } catch (error) {
            console.error('Error fetching MAC addresses:', error);
            setMacAddresses(['Error fetching MAC addresses']);
        }
    };

    useEffect(() => {
        if (userLocation?._id) {
            fetchMacAddresses();
        }
    }, [userLocation?._id]);

    return (
        <Stack mx={5}>
            <Typography variant='h4' fontWeight='600'>
                Network Requirements
            </Typography>
            <Typography variant='h6' mt={1}>
                Complete Network/IT requirements as detailed below
            </Typography>
            <Box sx={{ mt: 2 }}>
                <List>
                    <ListItem>
                        • The machine's internet is set up on a guest or isolated network that is not the organization's main firewall, allowing us to streamline the process and avoid unnecessary restrictions.
                    </ListItem>
                    <ListItem>
                        • Whitelist the machine(s)'s MAC address(es) for general internet access - {macAddresses.join(', ')}.
                    </ListItem>
                    <ListItem>
                        • 1 to 1 NAT through the network's firewall with source ACLs of 23.25.193.86,
                        71.174.235.63, 98.216.211.225, and 24.62.160.238 for UDP traffic both in and out
                        of port 3636.
                    </ListItem>
                </List>
            </Box>
        </Stack>
    );
};

export default Network;
